import React from 'react';
import './StepOptions.css';

export interface IStepOption {
  name: string;
  programCompany: string;
  programUrl: string;
  cost: string;
  timeToComplete: string;
  requirements: string;
  overview: string;
  programType: string;
  searchZip: string;
}

interface IProps {
  option: IStepOption;
}

const StepOption = ({ option }: IProps) => {
  return (
    <div className="mx-1 step-option-border rounded p-2 mt-3">
      <h6>{option.programCompany}</h6>
      <div className="row">       
          <span>{option.name}</span>     
      </div>
      <div className="row pt-3">
        <h6>
          {option.programType} | {option.cost} | {option.timeToComplete}
        </h6>
      </div>
      <div className="row pt-3">
        <span>{option.overview}</span>
      </div>
      <div className="row pt-3">
        <span>{option.requirements}</span>
      </div>
    </div>
  );
};

export default StepOption;
