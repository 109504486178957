import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { getCareerUrl, primaryPathDetailsUrl } from '../../urls';
import axios from 'axios';
import StepOptions from '../../components/StepOptions/StepOptions';
import Skills from '../../components/Skills/Skills';
import PrimaryPath from '../../components/CareerPath/PrimaryPath';
import SecondaryPaths from '../../components/CareerPath/SecondaryPaths';
import CareerHighlights from '../../components/CareerHighlights/CareerHighlights';
import Divider from '../../components/GeneralComponents/Divider/Divider';
import LoadingSpinner from '../../components/GeneralComponents/LoadingSpinner/LoadingSpinner';

const RadialEdgeBundlingChart = ({
  chartData,
  handleSetChatText,
  selectedStudent,
  accessToken,
  isChecked,
  searchZip
}) => {
  const svgRef = useRef(null);
  const [mustHave, setMustHave] = useState(undefined);
  const [optional, setOptional] = useState([]);
  const [paths, setPaths] = useState([]);
  const [secondaryPaths, setSecondaryPaths] = useState([]);
  const [selectedCareerOption, setSelectedCareerOption] = useState(null);
  const [selectedPath, setSelectedPath] = useState('');
  const [pathDetails, setPathDetails] = useState([]);

  const [loading, setLoading] = useState(false);
  const [pathLoader, setPathLoader] = useState(false);
  const targetRef = useRef(null);
  const loadingRef = useRef(null);
  

  let data = chartData ? chartData : [];
  const scrollToLoadingDiv = () => {
  if (loadingRef.current){
      loadingRef.current.scrollIntoView({
      behavior: 'smooth', // for smooth scrolling
      block: 'start',     // scroll to the start of the element
      top: document.body.scrollHeight,
    });
  }

};
  useEffect(() => {

    const containerWidth =
      document.getElementById('radial-container').clientWidth; // Increased width

    // Define margins and dimensions
    let margin;
    const nodeLength = data?.children?.length;
    let height;
    let width;

    if (nodeLength > 7) {
      margin = { top: 10, right: 5, bottom: 10, left: 5 }; // Adjust margins as needed
    } else {
      margin = { top: 5, right: 10, bottom: 5, left: 10 }; // Adjust margins as needed
    }

    if (nodeLength > 7) {
      height = 600 - margin.top - margin.bottom; // Adjust the height of the SVG container for more bubbles
      width = containerWidth; // Increase width for more bubbles
    } else if (nodeLength < 5) {
      height = 500 - margin.top - margin.bottom; // Adjust the height of the SVG container for fewer bubbles
      width = containerWidth; // Default width for fewer bubbles
    } else {
      height = 550 - margin.top - margin.bottom; // Default height for 5 to 7 bubbles
      width = containerWidth; // Default width for 5 to 7 bubbles
    }

    const radius = Math.min(width, height) / 2;

    // Check if SVG container already exists
    let svgContainer = d3.select('#radial-container svg');

    // If SVG container doesn't exist, create it
    if (svgContainer.empty()) {
      svgContainer = d3
        .select('#radial-container')
        .append('svg')
        .attr('width', containerWidth) // Set the width of the SVG container to match the parent container
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr(
          'transform',
          `translate(${containerWidth / 2}, ${(height + margin.top + margin.bottom) / 2})`
        ); // Center the SVG container within the parent container
    } else {
      // Clear existing content
      svgContainer.selectAll('*').remove();
    }

    // Proceed with drawing the chart inside the SVG container
    // Make sure to append elements to `svgContainer` and not to `d3.select("#radial-container")`

    // Proceed with drawing the chart inside the SVG container
    // Make sure to append elements to `svgContainer` and not to `d3.select("#radial-container")`

    function drawChart(svg) {
      // Calculate number of nodes
      const nodeLength = data?.children.length;
      // Sort the data array based on priority
      const sortedData = data.children.sort((a, b) => b.priority - a.priority);

      // Create a hierarchy from the sorted data
      const hierarchy = d3.hierarchy({
        name: '',
        children: sortedData,
      });

      // Adjust cluster size based on radius and number of nodes
      const cluster = d3.cluster().size([360, Math.min(500, radius - 80)]); // Limit the cluster size to avoid overlapping

      // Assign data to hierarchy
      const root = cluster(hierarchy);

      // Create a color scale for the gradient from dark green to light green
      const colorScale = d3
        .scaleLinear()
        .domain([1, 10])
        .range(['#10a37f', '#10a37f']);

      // Draw links
      svg
        .selectAll('path.link')
        .data(root.links())
        .enter()
        .append('path')
        .attr('class', 'link')
        .attr(
          'd',
          d3
            .linkRadial()
            .angle((d) => (d.x / 180) * Math.PI)
            .radius((d) => d.y)
        )
        .style('fill', 'none')
        .style('stroke', '#d9d8db')
        .style('stroke-width', 4);

      // Calculate circle radius based on priority
      const rediousCal = (d) => {
        // Handle undefined or non-existent priority values
        if (d.data.priority) {
          // Calculate the radius increment based on the difference
          let radiusIncrement = 7;
          let extraAdd = 5;
          if (nodeLength > 5) {
            radiusIncrement = 3;
          }

          if (nodeLength > 10) {
            radiusIncrement = 2;
            extraAdd = 1;
          }

          // Invert the priority value to ensure smaller values correspond to larger radii
          const invertedPriority = nodeLength + 1 - d.data.priority;
          // Calculate the radius based on the inverted priority value and the difference
          const radius = invertedPriority * radiusIncrement + extraAdd; // Adjust initial value as needed
          // Ensure the radius is within a reasonable range
          return radius;
        } else {
          // Default radius if priority is not available
          return 5; // Adjust as needed
        }
      };

      // Draw nodes
      const nodeGroups = svg
        .selectAll('g.node-group')
        .data(root.descendants().slice(1)) // Exclude the root node
        .enter()
        .append('g')
        .attr('class', 'node-group')
        .attr('transform', (d) => {
          const angle = d.x - 90;
          const x = Math.cos((angle / 180) * Math.PI) * d.y;
          const y = Math.sin((angle / 180) * Math.PI) * d.y;
          return `translate(${x},${y})`;
        })
        .on('click', handleClick)
        .on('mouseover', handleMouseOver)
        .on('mouseout', handleMouseOut);

      // Add user icon in the center
      const iconSize = 50; // Adjust icon size as needed
      const centerX = 0; // Center X coordinate
      const centerY = 0; // Center Y coordinate
      svg
        .append('foreignObject')
        .attr('width', iconSize)
        .attr('height', iconSize)
        .attr('x', centerX - 15) // Adjust the position based on icon size
        .attr('y', centerY - 10) // Adjust the position based on icon size and desired offset
        .html(
          "<i class='bi bi-person-circle' style='font-size: 28px; color: #949494;'></i>"
        );

      // Add text below user icon
      svg
        .append('text')
        .attr('x', centerX) // Center X coordinate
        .attr('y', centerY + iconSize) // Position below the user icon
        .attr('text-anchor', 'middle')
        .attr('class', 'user-text')
        .style('font-size', '14px') // Adjust font size
        .text('Student'); // Placeholder text, replace with actual user role if available

      // Add circles to the node groups
      nodeGroups
        .append('circle')
        .attr('class', 'node')
        .attr('r', (d) => rediousCal(d))
        .style('fill', (d) => colorScale(d.data.priority))
        .style('stroke', '#d9d8db')
        .style('stroke-width', 4)
        .append('title')
        // .text((d) => {
        //   return `\u2022 Name: ${d.data.name}\n\n\u2022 Description: ${d.data.description}\n\n\u2022 Suggestion(${d.data.priority}): ${d.data.priorityText}\n\n\n \u2022\u2022 Note: The size of the bubble should indicate the level of guidance, with larger bubbles suggesting stronger recommendations.`;
        // });

      // Add text to the circles
      nodeGroups
        .append('text')
        .attr('dy', (d) => rediousCal(d) + 20)
        .attr('text-anchor', 'middle')
        .attr('class', 'text-node')
        .text((d) => d.data.name)
        .style('font-size', '16px') // Adjust font size

        .style('fill', '#212529') // Set font color to a shade of orange
        .style('cursor', 'pointer')
        // .style("text-shadow", "0px 0px 2px #ffffff") // Add light text shadow for better visibility on dark backgrounds
        .on('click', function (event, d) {learningOptionsUrl
          handleClick(event, d, svg);
        });

      // Function to handle mouseover event
      function handleMouseOver(event, d) {
        d3.select(this)
          .select('circle')
          .transition(1000)
          .duration(100)
          .attr('r', (d) => rediousCal(d) + 6)
          .style('stroke-width', 6);
      }

      // Function to handle mouseout event
      function handleMouseOut(event, d) {
        d3.select(this)
          .select('circle')
          .transition(1000)
          .duration(200)
          .attr('r', (d) => rediousCal(d));
      }

      // Function to handle click event
      function handleClick(event, d) {
        event.stopPropagation(); // Stop event propagation
        scrollToLoadingDiv();
        setLoading(true);
        setSelectedCareerOption(d.data);
        if (d.data.name !== '') {
          const courseName = d.data.name;

          const getNextData = async () => {
            try {
              const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_DOMAIN}${getCareerUrl}`,
                {
                  prompt: 'Provide career/course path for ' + courseName,
                },
                {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                  },
                }
              );

              if (response.status === 200) {
                const responseData = response.data;
                const skillPathsArray = Object.entries(
                  responseData.skillsPaths
                ).map(([skill, steps]) => {
                  return [...steps]; // Include the key in the respective array
                });

                responseData.pathToReach.secondaryPaths.push(
                  ...skillPathsArray
                );
                setMustHave(responseData.requiredSkills.mustHave);
                setOptional(responseData.requiredSkills.optional);
                setPaths(responseData.pathToReach.primaryPath);
                setSecondaryPaths(responseData.pathToReach.secondaryPaths);

              } else {
                console.error('Failed to fetch data:', response.statusText);
              }
            } catch (error) {
              console.error('Error fetching data:', error);
            } finally {
              setLoading(false);
            }
          };

          // Reset border color of all bubbles
          svg.selectAll('.node').style('stroke-width', 4);

          // svg.selectAll(".node").style("stroke", "#d9d8db");
          svg.selectAll('.text-node').style('font-size', '15px');
          svg.selectAll('.text-node').style('fill', '#212529');

          // Change border color of the clicked bubble
          d3.select(this).select('circle').style('stroke-width', 6); // You can set any color you prefer here

          // Change border color of the clicked bubble
          d3.select(this)
            .select('text')
            // .style("font-size", "16px")
            .style('fill', '#10a37f');
          getNextData();
          console.log(`Clicked on ${courseName}`);
          // Trigger your API call or other actions here
        }
      }
    }

    // Render SVG if chartData is available
    if (chartData) {
      const svg = d3
        .select(svgRef.current)
        .attr('width', containerWidth)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', `translate(${width / 2},${height / 2})`);

      drawChart(svg);
    }
  }, [chartData]);

const handleSCrollToTarget = () => {
  if (targetRef.current){
    targetRef.current.scrollIntoView({
      behavior: 'smooth', // for smooth scrolling
      block: 'start',     // scroll to the start of the element
      top: document.body.scrollHeight,
    });
  }

};



  const handlePathClick = (e, path) => {
    e.preventDefault();
    setSelectedPath('');
    setSelectedPath(path);
    handleSCrollToTarget();
  };

  const isSectionDividerVisible = () => {
    if (
      !loading &&
      (mustHave ||
        paths?.length > 0 ||
        selectedCareerOption ||
        secondaryPaths?.length > 0 ||
        selectedPath)
    ) {
      return true;
    }

    return false;
  };

  return (
    <div className="row data-section mb-1">
      <div id="radial-container" style={{ width: '100%', margin: '0 auto' }}>
        <svg style={{ width: '100%' }} ref={svgRef}></svg>
      </div>

      <div className="sections">
        <div className="divider-break">
          <Divider visible={isSectionDividerVisible()} />
        </div>
        <div ref={loadingRef}>
        {loading && <LoadingSpinner />}
        </div>

        {!loading && mustHave && (
          <div className="skill-section custom-paper">
            <Skills
              loading={loading}
              loadingSpinnerEnabled={false}
              mustHave={mustHave}
            />
          </div>
        )}

        {!loading && paths?.length > 0 && (
          <div className="primary-path-section custom-paper">
            <PrimaryPath
              paths={paths}
              loading={loading}
              loadingSpinnerEnabled={false}
              handleClick={handlePathClick}
              isChecked={isChecked}
              searchZip={searchZip}
            />
          </div>
        )}

        {!loading && selectedCareerOption && (
          <div className="career-highlights-section custom-paper">
            <CareerHighlights
              selectedPath={selectedCareerOption}
              loading={loading}
              loadingSpinnerEnabled={false}
            />
          </div>
        )}

        {!loading && secondaryPaths?.length > 0 && (
          <div className="secondary-path-section custom-paper">
            <SecondaryPaths
              secondaryPaths={secondaryPaths}
              handleClick={handlePathClick}
              loading={loading}
              loadingSpinnerEnabled={false}
            />
          </div>
        )}
        <div ref={targetRef}>
        {!loading && selectedPath && (
          <div  className="step-options-section custom-paper">
            <StepOptions
              selectedPath={selectedPath}
              searchZip={searchZip}
              loadingSpinnerEnabled={true}
            />
          </div>
        )}
        </div>
      </div>
    </div>
  );
};

export default RadialEdgeBundlingChart;
