import React from 'react';
import Header from '../components/Header/Header';
import { Routes, Route } from 'react-router-dom';
import Student from '../components/Student/Student';
import { LogoutOptions } from '@auth0/auth0-react';

interface IProps {
  isLoggedIn: boolean;
  onLogout: (options?: LogoutOptions | undefined) => Promise<void>;
}

const DashboardLayout = ({ isLoggedIn = false, onLogout }: IProps) => (
  <div className="dashboard-layout">
    <Header isLoggedIn={isLoggedIn} onLogout={onLogout} />
    <div>
      <Routes>
      <Route path="/" element={<Student />} />
        <Route path="/dashboard/students" element={<Student />} />
        <Route path="/dashboard/coming-soon" element={<p>Coming Soon</p>} />
      </Routes>
    </div>
    <div className="text-center pt-1 pb-1">
      <h6 className="version-legal-text">
        Edge Build &quot;Anaheim&quot; Version v0.1.0 and ©️ 2024 LifeMappa LLC
      </h6>
    </div>
  </div>
);

export default DashboardLayout;
