import React, { useEffect, useState, useRef } from 'react';
import { chatUrl } from '../../urls';
import axios from 'axios';
import Tree from '../../pages/Search/Search';
import { useAuth0 } from '@auth0/auth0-react';
import LoadingSpinner from '../GeneralComponents/LoadingSpinner/LoadingSpinner';
import './Student.css';
import TextInput from '../GeneralComponents/TextInput/TextInput';
import Checkbox from '../GeneralComponents/Checkboox/Checkbox';
// import { text } from 'd3';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import HowItWorks from './../../images/svgs/HowItWorks.svg';

const Student = () => {
  const MAX_TEXT_LENGTH = 1000;
  const [searchQuery, setSearchQuery] = useState('');
  const [data, setData] = useState('');
  const [loader, setLoader] = useState(false);
  const [newChat, setNewChat] = useState(false);
  const [student, setStudent] = useState('');
  const [studentPrompt, setStudentPrompt] = useState('');
  const { getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = React.useState<string>();
  const [isFocused, setIsFocused] = useState(false);
  const textareaRef = useRef(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [searchZip, setSearchZipQuery] = useState('');
  const [isLoading, setLoading] = useState(true);
  const maxCharacters = 1000;
  useEffect(() => {
    const getToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        setAccessToken(token);
      } catch (error) {
        console.error(error);
      }
    };

    getToken();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea['scrollTop'] = textarea['scrollHeight'];
      
    }
  }, [searchQuery]);

  const handleSetChatText = (text: string, name: string) => {
    setIsChecked(isChecked);
    setStudent(name);
    setSearchQuery(text);
    setStudentPrompt(text);
    handleSearch(text, true);
  };

  const handleSearch = async (searchText?: string, isNewChat?: boolean) => {
    setLoader(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVICE_DOMAIN}${chatUrl}`,
        {
          prompt: searchText ? searchText : searchQuery,
          isGraduate: isChecked,
          isNewChat: isNewChat || newChat,
          zipCode: searchZip
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("data length: "+ response.data)
        setData(response.data);
        setNewChat(false);
      } else {
        console.error('Failed to fetch data:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoader(false);
    }
  };


  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
     if (searchQuery != '') handleSearch();
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;

    if (value.length <= MAX_TEXT_LENGTH) {
      setSearchQuery(value);

    }
  };

  const handleClick = () => {
    setSearchQuery('');
    setNewChat(true);
    setStudent('');
    setData('');
  };

  
  const handleZipChange = (e: React.ChangeEvent<HTMLInputElement>) => {   
    setSearchZipQuery(e.target.value);
};

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    if (searchQuery === "")  setIsFocused(true);
  }
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange =  (event: React.ChangeEvent<HTMLInputElement>) => {
    
    setIsChecked(event.target.checked);
  };
  const handleImageLoad = () => {
   setLoader(false);
   setLoading(false);
  };
  const remainingCharacters = maxCharacters - searchQuery.length;
  const warningStyle = remainingCharacters <= 50 ? { color: 'red' } : {};
  return (
   <>
    <Modal show={show} loader={loader} onHide={handleClose}>
    {/* style={{ maxWidth: '100%', height: 'auto' }} */}
       <Modal.Body>
       {isLoading && <div>Loading...</div>}
        <img src={HowItWorks} onLoad={handleImageLoad}
          style={{ display: isLoading ? 'none' : 'block', maxWidth: '100%', height: 'auto' }}  /></Modal.Body>
       <Modal.Footer>
         <button  className="btn btn-primary btn-md rounded ms-3 submit-button"
                  type="submit"
                  style={{
                    backgroundColor: '#e6f3f3',
                    color: '#10a37f',
                    borderColor: '#10a37f',}} onClick={handleClose}>
           Close
         </button>
         
       </Modal.Footer>
     </Modal>
   
    <div className="student-root">
      <div className="row">
        <div className="col-12 text-end">
          <div className="feedback-link pt-4 pb-4">
            <p>
              <a
                href="https://forms.gle/zWpuaCphmhnNRVNM9"
                target="_blank"
                rel="noreferrer noopener"
              >
                Click here to leave us a feedback
              </a>
            </p>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit} className="searchbar-wrapper">
      
        <div className="container">
       
          <div className='input-group'>
          <p style={{ width:'100%', boxSizing: 'border-box', borderRadius : '10px'
                    }}><a href="#" style={{fontSize: '18px',
                      color: '#10a37f', 
                      borderColor: '#10a37f'}} onClick={handleShow}>How it works?</a></p>
            <textarea
              ref={textareaRef}
              maxLength={MAX_TEXT_LENGTH}
              className="form-control form-control-ms custom-focus"
              placeholder="My daughter is a 14 year old high school freshman. She loves music, history and biology. She also loves horses and has been riding since she was 6. My daughter has many medals won at horse riding competitions. Can she combine these interests into a career."
              value={searchQuery}
              onChange={handleTextChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              rows={4}
              cols={40}
              style={{padding:'10px', width:'72%', boxSizing: 'border-box', border: '1px solid #ccc', borderRadius : '10px'}}
              />
              <div className="footer input-group options-group d-flex align-items-right"  id="char-count"><span style={warningStyle} className="char-count">{`${remainingCharacters}`} characters</span></div>
          </div>
          <div className="buttons">
              <div className="button-row">
                <button
                  className="btn btn-primary btn-md submit-button"
                  type="submit"
                  style={{
                    backgroundColor: '#e6f3f3',
                    color: '#10a37f',
                    borderColor: '#10a37f',
                  }}>
                  {loader ? (
                    <div className="spinner-block">
                      <LoadingSpinner />
                    </div>
                  ) : (
                    <i className="bi bi-search"></i>
                  )}
                </button>
                <button
                  className="btn btn-primary btn-md rounded ms-3 submit-button"
                  type="submit"
                  style={{
                    backgroundColor: '#e6f3f3',
                    color: '#10a37f',
                    borderColor: '#10a37f',
                    
                  }}
                  onClick={handleClick}
                  >
                  Reset Search
                </button>
                  
              </div>
              <div className="button-row">
                <div className='input-group d-flex' style={{marginTop: 5}}>
              
               

                <div className="form-check checkbox">
                  <input className="form-check-input" type="checkbox"   checked={isChecked} style={{borderColor: '#10a37f'}}
                    onChange={handleCheckboxChange} id="option1" name="option1" />
                  <label className="form-check-label" htmlFor="option1">Graduated Highschool</label></div>


    
                <div style={{marginTop: 15}}>
                 
                   <input
              type="text"
              className="form-control form-control-md custom-focus"
              placeholder="Zip Code" style={{borderColor: '#10a37f'}}
              value={searchZip}
               pattern="\d{5}(-\d{4})?"
                title="Please enter a valid ZIP code."
              // required={true}
              onChange={handleZipChange} />
                
                </div>
                
                  
              </div>
          </div>
        </div>

       
        </div>

      </form>

      <div className="mt-3">
        {student &&  (
          <p className="m-0 pt-2">
            <h5>
              {' '}
              <em> Career options for &quot;{student}&quot; </em>{' '}
            </h5>
          </p>
        )}
       {!loader && data  &&  (
          <Tree
            chartData={data}
            handleSetChatText={handleSetChatText}
            selectedStudent={studentPrompt}
            accessToken={accessToken}
            isChecked = {isChecked}
            searchZip={searchZip}
          />
        )}
      </div>
    </div>
    </>
  );
};

export default Student;
