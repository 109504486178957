import React from 'react';
import './Divider.css';

interface IProps {
  visible: boolean;
}

const Divider = ({ visible = true }: IProps) => {
  if (!visible) {
    return null;
  }

  return (
    <div className={'divider-wrapper'}>
      <div className={'divider-inner'}></div>
    </div>
  );
};

export default Divider;
