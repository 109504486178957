import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignIn, faSignOut } from '@fortawesome/free-solid-svg-icons';
import './Header.css';
import logoImage from './../../images/logo.png';
import { useNavigate } from 'react-router-dom';
import { useAuth0, LogoutOptions } from '@auth0/auth0-react';


interface IProps {
  isLoggedIn: boolean;
 
  onLogout: (options?: LogoutOptions | undefined) => Promise<void>;
}


const Header = ({ isLoggedIn, onLogout }: IProps) => {
  const navigate = useNavigate();

  const { logout } = useAuth0();

  const handleLogout = () => {
    sessionStorage.removeItem('token');
    logout({ logoutParams: { returnTo: window.location.origin } });
    navigate('/login');
  };
  return (
    <div className="header">
      <div className="logo">
        <img  className="img-logo" src={logoImage} alt="Life Mappa"/>
      </div>
      <div className="header-icons">
        {isLoggedIn ? (
          <FontAwesomeIcon
            icon={faSignOut}
            className="icon"
            title="Logout"
            onClick={handleLogout}
          />
        ) : (
          <FontAwesomeIcon
            icon={faSignIn}
            className="icon"
            title="Login"
            onClick={() => navigate('/login')}
          />
        )}
      </div>
    </div>
  );
};

export default Header;
