import React from 'react';
interface IProps {
  paths: string[];
  isChecked:boolean;
  searchZip:string;
  handleClick(e: any, path: string): void;
}


const PrimaryPath = ({ paths = [],isChecked, searchZip,  handleClick }: IProps) => {
  if (paths.length === 0) {
    return null;
  }
  
  return (
    <>
      <h5>Primary Path</h5>
      <div className="primary-path">
      
        {paths.length > 0 &&
          paths.map((path, pathIndex) => (
            <React.Fragment key={pathIndex}>
              {pathIndex === 0 ? (
                <a
                  href="javascript:void(0)" target='#course-details'
                  onClick={(e) => handleClick(e, path)}
                >
                  <i className="bi bi-person-walking person-walking"></i>
                  <span className="icon-text">{path}</span>
                </a>
              ) : paths.length - 1 === pathIndex ? (
                <a
                  href="javascript:void(0)" target='#course-details'
                  onClick={(e) => handleClick(e, path)}
                >
                  <i className="bi bi-trophy-fill icon"></i>
                  <span className="icon-text">{path}</span>
                </a>
              ) : (
                <a
                  href="javascript:void(0)"
                  onClick={(e) => handleClick(e, path)}
                >
                  <i className="bi bi-arrow-right-circle icon"></i>
                  <span className="icon-text">{path}</span>
                </a>
              )}
            </React.Fragment>
          ))}
      </div>
    </>
  );
};

export default PrimaryPath;
