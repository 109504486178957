import React from 'react';

interface IProps {
  mustHave?: string[];
}

const Skills = ({ mustHave = [] }: IProps) => {
  if (mustHave?.length === 0) {
    return null;
  }

  // Limit the displayed skills to a maximum of 10
  const displayedSkills = mustHave.slice(0, 10);

  return (
    <div className="must-have-skills">
      <h5>Relevant Skills for Chosen Goal</h5>
      <h6>
        Search and Select a Goal to see the skills leading to success in that
        Goal
      </h6>
      {displayedSkills.length > 0 ? (
        <div className="row">
          {displayedSkills.map((item, index) => (
            <div key={index} className="col-3 primary-skill-item">
              <li>{item}</li>
            </div>
          ))}
        </div>
      ) : (
        <p>No relevant skills found.</p>
      )}
    </div>
  );
};

export default Skills;
