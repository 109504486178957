import React from 'react';
import DashboardLayout from './layouts/DashboardLayout';
import { BrowserRouter as Router } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const App = () => {
  const {
    loginWithRedirect,
    isLoading = true,
    isAuthenticated = false,
    logout,
  } = useAuth0();

  if (!isLoading && !isAuthenticated) {
    loginWithRedirect();
  }

  if (isLoading || !isAuthenticated) {
    return <p>Loading...</p>;
  }

  return (
    <div className="container-fluid main">
      <Router>
        <DashboardLayout isLoggedIn={isAuthenticated} onLogout={logout} />
        {/* TODO: Refactor routing and include this path. <Route path="*" element={<ErrorPage />} /> */}
      </Router>
    </div>
  );
};

export default App;
