import React from 'react';
import { Spinner } from 'react-bootstrap';

const LoadingSpinner = () => {
  return (
    <div className="spinner-block">
      <Spinner animation="grow" variant="success" size="sm" />
      <Spinner animation="grow" variant="danger" size="sm" />
      <Spinner animation="grow" variant="warning" size="sm" />
    </div>
  );
};

export default LoadingSpinner;
