import React, { useEffect, useState } from 'react';
import { learningOptionsUrl } from '../../urls';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import StepOption, { IStepOption } from './StepOption';
import LoadingSpinner from '../GeneralComponents/LoadingSpinner/LoadingSpinner';

export interface ISelectedPath {
  name: string;
  priorityText: string;
  priority: number;
  salary: string;
  description: string;
}


interface IProps {
  learnerSearchText: null;
  selectedPath: string;
  searchZip: string;
  loadingSpinnerEnabled?: boolean;
}

const StepOptions = ({
  learnerSearchText = null,
  selectedPath,
  searchZip,
  loadingSpinnerEnabled = false,
}: IProps) => {
  const [internalLoading, seInternalLoading] = useState(true);
  const { getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = React.useState<string>();
  const [stepOptions, setStepOptions] = useState<IStepOption[]>([]);

  useEffect(() => {
    const getToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        setAccessToken(token);
      } catch (error) {
        console.error(error);
      }
    };

    getToken();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    setStepOptions([]);
    seInternalLoading(true);

    const getCollegeOptions = async () => {
      try {
        if (!accessToken) {
          return;
        }
        const response = await axios.post(
          `${process.env.REACT_APP_SERVICE_DOMAIN}${learningOptionsUrl}`,
          {
            selectedPath,
            searchZip,
            
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (response.status === 200) {
          const programs = response.data;
          setStepOptions(programs);
        } else {
          console.error('Failed to fetch data:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        seInternalLoading(false);
      }
    };

    getCollegeOptions();
  }, [accessToken, selectedPath]);

  if (stepOptions?.length === 0 && !internalLoading) {
    return null;
  }

  return (
    <div className="course-details pb-3">
      <h5 className="mb-2 mb-1 mx-1">Program Options ({stepOptions.length})</h5>

      {loadingSpinnerEnabled && internalLoading && <LoadingSpinner />}

      {!internalLoading &&
        stepOptions?.length > 0 &&
        stepOptions.map((option) => {
          return <StepOption key={JSON.stringify(option)} option={option} />;
        })}
    </div>
  );
};

export default StepOptions;
