import React from 'react';

interface IProps {
  secondaryPaths: string[][];
  handleClick(e: any, path: string): void;
}

const SecondaryPaths = ({ secondaryPaths = [], handleClick }: IProps) => {
  if (secondaryPaths.length === 0) {
    return null;
  }

  return (
    <>
      <h5> Secondary Paths </h5>
      <div className="secondary-paths">
        {secondaryPaths.length > 0 &&
          secondaryPaths.map((paths, index) => (
            <div key={index}>
              {paths.map((path, pathIndex) => (
                <span key={pathIndex}>
                  {pathIndex === 0 ? (
                    <a
                      href="javascript:void(0)"
                      onClick={(e) => handleClick(e, path)}
                    >
                      <i className="bi bi-person-walking person-walking"></i>
                      <span className="icon-text">{path}</span>
                    </a>
                  ) : paths.length - 1 === pathIndex ? (
                    <a
                      href="javascript:void(0)"
                      onClick={(e) => handleClick(e, path)}
                    >
                      <i className="bi bi-trophy-fill icon"></i>
                      <span className="icon-text">{path}</span>
                    </a>
                  ) : (
                    <a
                      href="javascript:void(0)"
                      onClick={(e) => handleClick(e, path)}
                    >
                      <i className="bi bi-arrow-right-circle icon"></i>
                      <span className="icon-text">{path}</span>
                    </a>
                  )}
                </span>
              ))}
            </div>
          ))}
      </div>
    </>
  );
};

export default SecondaryPaths;
