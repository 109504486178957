import React from 'react';
import { ISelectedPath } from '../StepOptions';
import Divider from '../GeneralComponents/Divider/Divider';

interface IProps {
  selectedPath: ISelectedPath | null;
}

const CareerHighlights = ({ selectedPath }: IProps) => {
  const isSelectedPathEmpty = (): boolean => {
    return !(
      selectedPath &&
      selectedPath.name &&
      selectedPath.salary &&
      selectedPath.priorityText &&
      selectedPath.description &&
      selectedPath.priority
    );
  };

  if (isSelectedPathEmpty()) {
    return null;
  }

  return (
    <>
      <h5>{selectedPath?.name} Career Highlights</h5>
      <Divider visible />
      <div className="row">
        <div className="col-4">
          <div className="row">
            <span>
              <label>
                <h6>Name: </h6>
              </label>
              <span> {selectedPath?.name} </span>
            </span>
          </div>

          <div className="row">
            <span>
              <label>
                <h6>Suggestion: </h6>
              </label>
              <span> {selectedPath?.priorityText} </span>
            </span>
          </div>

          <div className="row">
            <span>
              <label>
                <h6>Salary: </h6>
              </label>
              <span> {selectedPath?.salary} </span>
            </span>
          </div>
        </div>

        <div className="col-8">
          <div className="row">
            <label>
              <h6>Description: </h6>
            </label>
          </div>
          <div className="row">
            <span>{selectedPath?.description}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default CareerHighlights;
